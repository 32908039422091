import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthenticationGuard } from './auth/authentication.guard';


const routes: Routes = [{
  path: 'dashboard', loadChildren: () => import('./dash-board/dash-board.module').then(m => m.DashBoardModule)
  , data: { header: false },canActivate:[AuthenticationGuard]
},
{
  path: '',
  component: LoginComponent,
  pathMatch: 'full', data: { header: false }
},

{path:'actionplans',loadChildren:()=>import('./actionplans/actionplans.module').then(m=>m.ActionPlansModule),data:{header:true},canActivate:[AuthenticationGuard]},
{ path: 'tags', loadChildren: () => import('./tags/tags.module').then(m => m.TagsModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'dealers-groups', loadChildren: () => import('./dealers-groups/dealers-groups.module').then(m => m.DealersGroupsModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'dealership', loadChildren: () => import('./dealer-ship/dealer-ship.module').then(m => m.DealerShipModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'cmsmodule', loadChildren: () => import('./cmsmodules/cmsmodules.module').then(m => m.CmsmodulesModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), data: { header: false } },
{ path: 'cmsusers', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'Roles', loadChildren: () => import('./cmsusers/cmsusers.module').then(m => m.CmsusersModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'brands', loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'department', loadChildren: () => import('./department/department.module').then(m => m.DepartmentModule), data: { header: true } ,canActivate:[AuthenticationGuard]},
{ path: 'Permissions', loadChildren: () => import('./cmspermissions/cmspermissions.module').then(m => m.CmspermissionsModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'InvPlans', loadChildren: () => import('./invplans/invplans.module').then(m => m.InvplansModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'dealersusers', loadChildren: () => import('./storecontacts/storecontacts.module').then(m => m.StorecontactsModule), data: { header: true } ,canActivate:[AuthenticationGuard]},
{ path: 'dealercontractform', loadChildren: () => import('./dealercontractform/dealercontractform.module').then(m => m.DealercontractformModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'dealer-hours', loadChildren: () => import('./dealer-hours/dealer-hours.module').then(m => m.DealerHoursModule), data: { header: true } ,canActivate:[AuthenticationGuard]},
{ path: 'showUsers', loadChildren: () => import('./show-users/show-users.module').then(m => m.ShowUsersModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'subscription-details', loadChildren: () => import('./subscription-details/subscription-details.module').then(m => m.SubscriptionDetailsModule), data: { header: true },canActivate:[AuthenticationGuard] },
{ path: 'user-profile-page', loadChildren: () => import('./user-profile-page/user-profile-page.module').then(m => m.UserProfilePageModule), data: { header: true } ,canActivate:[AuthenticationGuard]},
{ path: 'inventory', loadChildren: () => import ('./inventory/inventory.module').then(m=>m.InventoryModule), data : {header: true},canActivate:[AuthenticationGuard] },
{ path: 'software-agreement', loadChildren: () => import ('./software-agreement/software-agreement.module').then(m=>m.SoftwareAgreementModule), data : {header: true},canActivate:[AuthenticationGuard] },
{ path: 'viewlabels', loadChildren : ()=> import ('./viewlabels/viewlabels.module').then(m =>m.ViewlabelsModule), data : {header: true} ,canActivate:[AuthenticationGuard]},
{ path: 'dealerweightedaverage', loadChildren: () => import ('./dealer-content/dealer-content.module').then(m=>m.DealerContentModule), data : {header: true},canActivate:[AuthenticationGuard] },
{ path:'taskstatustypes',loadChildren:() => import('./taskstatustype/taskstatustype.module').then(m=>m.TaskstatustypeModule),data:{header:true},canActivate:[AuthenticationGuard]},
{​​​​​​​​ path:'subsections',loadChildren:()=>import('./section-names/SectionNames.module').then(m=>m.SectionNamesModule),data:{​​​​​​​​header:true},canActivate:[AuthenticationGuard]​​​​​​​​}​​​​​​​​,
{ path: 'actionplandept', loadChildren:()=>import('./actionplandept/actionplandept.module').then(m=>m.ActionplandeptModule),data:{header:true},canActivate:[AuthenticationGuard]},
{ path: 'dealervariables', loadChildren: () => import('./dealer-variables/dealer-variables.module').then(m => m.DealerVariablesModule),canActivate:[AuthenticationGuard] },
{ path: 'sectiontitles', loadChildren: () => import('./section-titles/section-titles.module').then(m => m.SectionTitlesModule) ,canActivate:[AuthenticationGuard]},
{​​​​​​​​ path:'passwordreset/:id', loadChildren: () =>import('./forgotpassword/forgotpassword.module').then(m=>m.ForgotpasswordModule),data:{​​​​​​​​​header:false}​​​​​​​​}​​​​​​​​​​​​,
{ path: 'userloginactivities', loadChildren: () => import('./user-login-activities/user-login-activities.module').then(m => m.UserLoginActivitiesModule),canActivate:[AuthenticationGuard] },
{ path: 'dealermonths', loadChildren: () => import('./dealermonths/dealermonths.module').then(m => m.DealermonthsModule),canActivate:[AuthenticationGuard] },
{ path: 'staffinglabels', loadChildren: () => import('./staffing-labels/staffing-labels.module').then(m =>m.StaffingLabelsModule),canActivate:[AuthenticationGuard]},
{ path: 'staffingsubsectionnames', loadChildren: () => import('./staffingsubsectionnames/staffingsubsectionnames.module').then(m =>m.StaffingsubsectionnamesModule),canActivate:[AuthenticationGuard]},
{ path: 'dealerdays-supply', loadChildren: () => import('./dealerdays-supply/dealerdays-supply.module').then(m => m.DealerdaysSupplyModule) ,canActivate:[AuthenticationGuard]},
{ path: 'dealeruserloginactivity', loadChildren: () => import('./user-login-activities-frontend/user-login-activities-frontend.module').then(m => m.UserLoginActivitiesFrontendModule),canActivate:[AuthenticationGuard]},
{ path: 'taskmanagement', loadChildren: () => import('./taskmanagement/task.module').then(m => m.TaskModule),canActivate:[AuthenticationGuard] },
{ path: 'Migration', loadChildren: () => import('./migration/migration.module').then(m => m.MigrationModule) ,canActivate:[AuthenticationGuard] },
{ path: 'users-history', loadChildren: () => import('./users-history/users-history.module').then(m => m.UsersHistoryModule),canActivate:[AuthenticationGuard]  },
{ path: 'cdktracking', loadChildren: () => import('./cdk-tracking/cdk-tracking.module').then(m => m.CDKTrackingModule),canActivate:[AuthenticationGuard] },
{ path: 'dmsdata', loadChildren: () => import('./dms-data/dms-data.module').then(m => m.DMSDataModule),canActivate:[AuthenticationGuard] },
{ path: 'migration-summary', loadChildren: () => import('./migration-summary/migration-summary.module').then(m => m.MigrationSummaryModule),canActivate:[AuthenticationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
